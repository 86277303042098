import React, { useState, useEffect } from 'react';
import Select, { SingleValue } from 'react-select';
import { VictoryChart, VictoryBar, VictoryAxis, VictoryVoronoiContainer, VictoryTooltip } from 'victory';

import { useTranslation } from 'react-i18next';

interface DataPoint {
  x: Date;
  y: number;
}

interface APIDataPoint {
  datetime: string;
  value: number;
}

const ChartValueTeam: React.FC = () => {
  const [timeframe, setTimeframe] = useState('week');
  const [data, setData] = useState<DataPoint[]>([]);
  const [currency, setCurrency] = useState(localStorage.getItem('pxlCurrency') || 'USD');
  const { t } = useTranslation();
  const currentHostname = window.location.href;
  const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
  const linkUrl = currentHostname.includes(`${stagingKey}`)
    ? `https://${stagingKey}.peaxel.me`
    : 'https://peaxel.me';

  const walletAddress = localStorage.getItem('walletAddress');
  const devise = localStorage.getItem('pxlCurrency');

  useEffect(() => {
    if (walletAddress) {
      fetch(`${linkUrl}/wp-json/custom/v1/get-portfolio-data/?wallet_address=${walletAddress}&timeframe=${timeframe}&currency=${devise}`)
        .then((response) => response.json())
        .then((apiData: APIDataPoint[]) => {
          const validData = apiData.map((d) => ({
            x: new Date(d.datetime),
            y: d.value
          }));

          const sortedData = validData.sort((a, b) => a.x.getTime() - b.x.getTime());
          let limitedData = sortedData;

          // Limit data points based on the timeframe
          if (timeframe === 'week') {
            limitedData = sortedData.slice(-7);
          } else if (timeframe === 'month') {
            limitedData = sortedData.slice(-30);
          } else if (timeframe === 'year') {
            limitedData = sortedData.slice(-365);
          }

          setData(limitedData);
        });
    }
  }, [timeframe, currency, walletAddress]);

  const handleCurrencyChange = (selectedOption: SingleValue<{ value: string; label: string }>) => {
    if (selectedOption) {
      setCurrency(selectedOption.value);
    }
  };

  const handleTimeframeChange = (selectedOption: SingleValue<{ value: string; label: string }>) => {
    if (selectedOption) {
      setTimeframe(selectedOption.value);
    }
  };

  const timeframeOptions = [
    { value: 'week', label: t('LastWeek') },
    { value: 'month', label: t('LastMonth') },
    { value: 'year', label: t('LastYear') }
  ];

  const currencyOptions = [
    { value: 'USD', label: 'USD' },
    { value: 'EUR', label: 'EUR' }
  ];

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: '#161616',
      color: '#D8D8D8',
      borderColor: '#343434',
      boxShadow: 'none',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: '#D8D8D8',
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: '#161616',
      color: '#D8D8D8',
    }),
    option: (provided: any) => ({
      ...provided,
      backgroundColor: '#161616',
      color: '#D8D8D8',
      '&:hover': {
        backgroundColor: '#333333',
      },
    }),
  };

  const yMax = Math.max(...data.map(d => d.y));

  return (
    <div style={{ width: '100%', maxHeight: '250px', padding: '0px', backgroundColor: '#161616', color: '#D8D8D8' }}>
      <VictoryChart
        width={1000}
        height={700} // Fixed height for the chart
        padding={{ top: 150, bottom: 40, left: 50, right: 50 }} // Adjust padding to remove excess space
        domainPadding={{ x: 20, y: 0 }} // Adds uniform padding between bars
        domain={{ y: [0, yMax] }} // Ensure y-axis starts from 0
        containerComponent={
          <VictoryVoronoiContainer
            labels={({ datum }) => `${datum.y}${currency}\n${datum.x.toLocaleDateString()}`}
            labelComponent={
              <VictoryTooltip
                style={{ fontSize: 40, fontWeight: 'bold', fill: '#FFFFFF' }} // Ensures tooltip text is large enough
                flyoutStyle={{ fill: '#161616', padding: 10, borderRadius: 2 }} 
              />
            }
          />
        }
      >
        <VictoryBar
          data={data}
          style={{
            data: {
              fill: ({ index }) => (index === data.length - 1 ? '#d4055d' : "#93003F"), // Last bar different color
              strokeWidth: 2,
              borderRadius: '30', // Rounded top of bars
            },
          }}
          barWidth={({ index }) => 900 / data.length - 5} // Uniform bar width
          alignment="middle" // Centers the bars
        />
        <VictoryAxis
          style={{
            axis: { stroke: "none" },
            ticks: { stroke: "none" },
            tickLabels: { fill: "none" }, // Hiding the labels on the x-axis
            grid: { stroke: "none" }
          }}
        />
        <VictoryAxis
          dependentAxis
          style={{
            axis: { stroke: "none" },
            ticks: { stroke: "none" },
            tickLabels: { fill: "none" }, // Hiding Y-axis labels
            grid: { stroke: "none" }
          }}
        />
      </VictoryChart>

      <div className="valueChartSelector">
  <div className="chartSelector2">
    <span 
      onClick={() => setTimeframe('week')} 
      style={{ 
        fontWeight: timeframe === 'week' ? 'bold' : 'normal', 
        color: timeframe === 'week' ? '#848484' : '#363636', 
        cursor: 'pointer', 
        marginRight: '10px' 
      }}
    >
      {t('LastWeek')}
    </span>
    <span 
      onClick={() => setTimeframe('month')} 
      style={{ 
        fontWeight: timeframe === 'month' ? 'bold' : 'normal', 
        color: timeframe === 'month' ? '#848484' : '#363636', 
        cursor: 'pointer', 
        marginRight: '10px' 
      }}
    >
      {t('LastMonth')}
    </span>
    <span 
      onClick={() => setTimeframe('year')} 
      style={{ 
        fontWeight: timeframe === 'year' ? 'bold' : 'normal', 
        color: timeframe === 'year' ? '#848484' : '#363636', 
        cursor: 'pointer' 
      }}
    >
      {t('LastYear')}
    </span>
  </div>
</div>


    </div>
  );
};

export default ChartValueTeam;